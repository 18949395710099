import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import LandingPage from "./pages/Landing/LandingPage";
import LoginPage from "./pages/Auth/LoginPage";
import SignupPage from "./pages/Auth/SignupPage";
import MediaSearchPage from "./pages/Photos/MediaSearchPage";
import CreateAlbumForm from "./pages/Dashboard/CreateAlbumForm";
import AlbumDetailsPage from "./pages/Dashboard/AlbumDetailsPage";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import VerifyEmailPage from "./pages/Auth/VerifyEmailPage";
import PleaseVerifyEmailPage from "./pages/Auth/PleaseVerifyEmailPage";
import PrivacyPolicy from "./pages/Landing/PrivacyPolicy";
import TermsOfService from "./pages/Landing/TermsOfService";
import CompleteProfilePage from "./pages/Auth/CompleteProfilePage";
import SurferLandingPage from "./pages/Landing/SurferLandingPage";
import PhotographerLandingPage from "./pages/Landing/PhotographerLandingPage";
import ForgotPasswordPage from "./pages/Auth/ForgotPasswordPage";
import ResetPasswordPage from "./pages/Auth/ResetPasswordPage";
import OrderDetailsPage from "./pages/OrderDetailsPage";
import PhotographerProfilePage from "./pages/PhotographerProfilePage";
import PublicAlbumDetailsPage from "./pages/PublicAlbumDetailsPage";
import EditProfilePage from "./pages/EditProfilePage";
import DashboardPage from "./pages/Dashboard/DashboardPage";

const AppContent: React.FC = () => {
  const location = useLocation();
  const isLandingPage = location.pathname === "/";

  return (
    <Layout fullWidth={isLandingPage} isLandingPage={isLandingPage}>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/media" element={<MediaSearchPage />} />
          <Route path="/orders/:sessionId" element={<OrderDetailsPage />} />
          <Route
            path="/photographer/:username"
            element={<PhotographerProfilePage />}
          />
          <Route
            path="/photographer/:username/album/:albumId"
            element={<PublicAlbumDetailsPage />}
          />
          <Route
            path="/verify-email/:uidb64/:token"
            element={<VerifyEmailPage />}
          />
          <Route
            path="/please-verify-email"
            element={<PleaseVerifyEmailPage />}
          />
          <Route path="/complete-profile" element={<CompleteProfilePage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route
            path="/reset-password/:uidb64/:token/"
            element={<ResetPasswordPage />}
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/create-album"
            element={
              <ProtectedRoute>
                <CreateAlbumForm />
              </ProtectedRoute>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <ProtectedRoute>
                <EditProfilePage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/album/:id"
            element={
              <ProtectedRoute>
                <AlbumDetailsPage />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<p>Not found.</p>} />
        </Routes>
    </Layout>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <AppContent />
    </Router>
  );
};

export default App;
